import { createSlice, createSelector } from '@reduxjs/toolkit';

// constants
export const availableMenu = [
  {
    key: 'ALL_WATCHLIST',
    icon: 'icon-widget_watchlist',
    text: 'Watchlist',
    eventName: 'watchlist',
  },
  {
    key: 'PRICE_ALERT',
    icon: 'icon-widget_alert',
    text: 'Price Alert',
    eventName: 'price_alert',
  },
  {
    key: 'RUNNING_TRADE',
    icon: 'icon-widget_runningtrade',
    text: 'Running Trade',
    eventName: 'running_trade',
  },
  {
    key: 'HOTLIST',
    icon: 'icon-widget_hotlist',
    text: 'Hotlist',
    eventName: 'hotlist',
  },
  {
    key: 'VALUATION',
    icon: 'icon-widget_valuation',
    text: 'Valuation',
    eventName: 'valuation',
  },
  {
    key: 'BANDAR_DETECTOR',
    icon: 'icon-widget_bandar',
    text: 'Bandar Detector',
    eventName: 'bandar_detector',
  },
  // {
  //   key: 'SUPPORT',
  //   icon: 'icon-widget_support',
  //   text: 'Support',
  // },
];

// initial state
export const initialState = {
  // current: availableMenu[0].key,
  current: null,
  isSideWidgetOpen: false,
  isHeaderMinimized: false,
  isReloginModalOpen: false,
  errorCode: null,
  bandarDetectorActiveKey: 'BROKER_SUMMARY',
  isFromBandarDetector: false,
  isMarketOpen: true,
};

// Selectors
export const selectors = createSelector(
  (state) => state.mainLayout.root,
  (state) => ({
    current: state.current,
    isSideWidgetOpen: state.isSideWidgetOpen,
    isReloginModalOpen: state.isReloginModalOpen,
    isHeaderMinimized: state.isHeaderMinimized,
    errorCode: state.errorCode,
    bandarDetectorActiveKey: state.bandarDetectorActiveKey,
    isMarketOpen: state.isMarketOpen,
  }),
);

// Async Actions / Side Effects
export const effects = {};

// Reducer
export const reducers = {
  setActiveWidget: (draft, action) => {
    if (
      draft.isSideWidgetOpen &&
      action.payload.key === draft.current &&
      !action.payload.isFromBandarDetector
    ) {
      draft.current = null;
      draft.isSideWidgetOpen = false;
    } else {
      draft.current = action.payload.key;
      draft.isSideWidgetOpen = true;
    }

    if (action.payload.key === availableMenu[5].key) {
      if (action.payload.bandarDetectorActiveKey) {
        draft.bandarDetectorActiveKey = action.payload.bandarDetectorActiveKey;
      } else {
        draft.bandarDetectorActiveKey = 'BROKER_SUMMARY';
      }
    }
  },
  setIsMarketOpen: (state, action) => {
    state.isMarketOpen = action.payload;
  },
  setErrorCode: (state, action) => {
    state.errorCode = action.payload;
    state.isReloginModalOpen = true;
  },
  setHeaderMinimized: (state, action) => {
    state.isHeaderMinimized = action.payload;
  },
  resetState: (state) => {
    state.current = null;
    state.isSideWidgetOpen = false;
    state.isHeaderMinimized = false;
    state.isReloginModalOpen = false;
    state.errorCode = null;
  },
};

// Extra Reducer
const extraReducers = {};

// Slice
const mainSlice = createSlice({
  name: 'mainLayout',
  initialState,
  reducers,
  extraReducers,
});

export default mainSlice;
